import {DefinitionSet} from "../types";
import {ProductPartial, ProductRenderDisplayVariant} from "./utils/get-variants-for-display";
import React from "react";
import retryWizard from "../../../plugins/gatsby-plugin-atollon/real-src/node/utils/retry-wizard";

export interface UseLoadableHotReturnable {
    products: null
    fieldDefinitions: null
    parsedPriceDefinitions: null
    isLoaded: false
    load: () => void
}
export interface UseLoadableHotReturnableFulfilled {
    products: ProductPartial[]
    fieldDefinitions: DefinitionSet
    parsedPriceDefinitions: Record<string, unknown>
    isLoaded: true,
    load: () => void
}
const useLoadableHot = (): UseLoadableHotReturnable|UseLoadableHotReturnableFulfilled => {

    const [products, setProducts] = React.useState<ProductPartial[]|null>(null)
    const [fieldDefinitions, setFieldDefinitions] = React.useState<DefinitionSet|null>(null)
    const [parsedPriceDefinitions, setParsedPriceDefinitions] = React.useState<Record<string, unknown>|null>(null)
    const [isLoaded, setIsLoaded] = React.useState(false)

    const load = async () => {
        const dataPoints = await retryWizard(() => {
            return Promise.all([
                (async () => {
                    const d = await fetch(`${process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://shadey-blinds.netlify.app"}/__c/parsed-price-definitions.json`)
                    return d.json();
                })(),
                (async () => {
                    const d = await fetch(`${process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://shadey-blinds.netlify.app"}/__c/products.json`)
                    return d.json();
                })(),
                (async () => {
                    const d = await fetch(`${process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://shadey-blinds.netlify.app"}/__c/field-definitions.json`)
                    return d.json();
                })()
            ])
        }, 3)

        if (dataPoints && dataPoints[0] && dataPoints[1] && dataPoints[2]) {
            setParsedPriceDefinitions(dataPoints[0])
            setProducts(dataPoints[1])
            setFieldDefinitions(dataPoints[2])
            setIsLoaded(true)
        }
    }

    if (isLoaded && products && fieldDefinitions && parsedPriceDefinitions) {
        return {
            isLoaded: true,
            products,
            parsedPriceDefinitions,
            fieldDefinitions,
            load
        }
    }

    return {
        isLoaded: false,
        products: null,
        parsedPriceDefinitions: null,
        fieldDefinitions: null,
        load
    }

}

export default useLoadableHot
